import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cibVk,
  cilPhone,
  cilPen,
  cilX,
  cilCheckAlt,
  cibTelegramPlane,
  cibWhatsapp,
  cibFacebookF,
  cilXCircle,
  cibInstagram,
  cilChevronTop,
  cilInfo,
  cilChevronCircleRightAlt,
  cilOptions,
  cilListFilter,
} from "@coreui/icons";
import Axios from "axios";
import { API_URL } from "utils/settings";
import { getDate, useLocalStorage } from "utils/utils";
import { CSpinner } from "@coreui/react";
import ToastComponent from "components/ToastComponent";
import { CTooltip, CButton } from "@coreui/react";
import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import Sorting from "components/Sorting";
import { useWindowSize } from "utils/utils";
import SwipeToDelete from "react-swipe-to-delete-ios";
import {
  cnCountries,
  enCountries,
  esCountries,
  ruCountries,
} from "components/ChooseCountries/countries";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
function OrderTable(props) {
  const {
    getOrders,
    orders,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    isLoading,
    filters,
    title,
    sorting,
    activeSorting,
    setSorting,
    setFilters,
    currency,
    updateUser,
    needCancel,
    needUser = false,
  } = props;

  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [loadingId, setLoadingId] = useState("");
  const windowSize = useWindowSize()[0];
  const [locale] = useLocalStorage("locale", "gb");

  const [count, setCount] = useState(0);
  const isMobile = windowSize < 991;
  const countriesObj = {
    ru: ruCountries,
    gb: enCountries,
    cn: cnCountries,
    es: esCountries,
  };
  const actualLanguage = countriesObj[locale];

  useEffect(() => {
    getOrders(page, perPage, filters, false);
  }, [page, activeSorting]);
  useEffect(() => {
    setPage(1);
  }, [perPage, filters]);
  useEffect(() => {
    if (
      orders.filter(
        (el) => el.status == "waiting_phone" || el.status == "waiting_sms"
      )?.length > 0
    ) {
      const interval = setInterval(() => {
        getOrders(page, perPage, filters, false);
      }, 10000);
      return () => clearInterval(interval);
    }
  }, []);

  const renderStatus = (status) => {
    const statusIcons = {
      waiting_phone: cilPhone,
      waiting_sms: cilPen,
      canceled: cilX,
      finished: cilCheckAlt,
    };

    if (statusIcons[status]) {
      return (
        <CTooltip content={<>{t(`table.${status}`)}</>}>
          <CIcon icon={statusIcons[status]} />
        </CTooltip>
      );
    } else {
      return status;
    }
  };
  const renderSms = (smsContent) => {
    if (smsContent) {
      return (
        <CTooltip content={<>{smsContent}</>}>
          <div className="sms-content-wrapper">
            <div className="sms-content">{smsContent} </div>
            <span className="question">?</span>
          </div>
        </CTooltip>
      );
    }
    return "—";
  };
  const renderIcon = (iconName, country, serviceName) => {
    return (
      <div className="d-flex align-items-center">
        <CTooltip content={<>{actualLanguage[country]}</>}>
          <div
            className={`flag flag-${country}`}
            style={{ marginLeft: "5px" }}
          />
        </CTooltip>
        <CTooltip content={<>{serviceName}</>}>
          <div className={`bg bg-${iconName}`} alt={iconName} />
        </CTooltip>
      </div>
    );
  };
  const cancelOrder = (id) => {
    setLoading(true);
    setLoadingId(id);
    Axios.put(`${API_URL}/order`, {
      orderId: id,
    })
      .then(function (response) {
        if (response.data.orderId) {
          ToastComponent("success", t("table.order_cancel"));
          getOrders(page, perPage, filters, false);
          setLoading(false);
          updateUser();
          setLoadingId("");
        }
      })
      .catch((error) => {
        setLoading(false);
        updateUser();
        setLoadingId("");
      });
  };
  const renderDate = (date) => {
    const newDate = new Date(date).toLocaleString();
    return (
      <div>
        <CTooltip content={<>{`${newDate}`}</>}>
          <div>{newDate?.split(",")[0] || "—"}</div>
        </CTooltip>
      </div>
    );
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "serviceUid",
      //   name: t("table.service"),
      name: "",
      center: true,
      compact: true,
      selector: (row) => (
        <div>
          {row.serviceUid &&
            renderIcon(row.serviceUid, row.countryAlpha2, row.serviceName)}
        </div>
      ),
      sortable: false,
      notMobile: true,
      // width: "90px",
    },
    {
      compact: true,
      id: "phone",
      allowOverflow: true,
      name: t("common.phone"),
      center: true,
      selector: (row) => row.phone || t("table.not_number"),
      sortable: false,
      // minWidth: "130px",
    },

    {
      id: "status",
      name: t("table.status"),
      center: true,
      selector: (row) =>
        row.status ? (
          <>
            <CTooltip content={<>{t(`table.${row.status}`)}</>}>
              <div>{t(`table.${row.status}`)}</div>
            </CTooltip>
          </>
        ) : (
          "—"
        ),
      sortable: false,
    },
    {
      id: "amount",
      name: t("table.amount"),
      center: true,
      selector: (row) => {
        if (row.amount) {
          return new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: currency || "USD",
          }).format(row.amount || 0);
        } else {
          return 0;
        }
      },
      sortable: false,
    },
    {
      id: "sms_content",
      name: t("table.sms_content"),
      wrap: true,
      center: true,
      // minWidth: "80px",
      height: "100%",
      selector: (row) => <div>{renderSms(row.smsContent)}</div>,
    },
    {
      id: "createDate",
      name: t("table.date"),
      center: true,
      selector: (row) => (row.createdAt ? renderDate(row.createdAt) : "—"),
      sortable: false,
      // minWidth: "80px",
      notMobile: true,
    },
  ];
  needCancel &&
    columns.push({
      id: "order_action",
      name: "",
      center: true,
      selector: (row) => {
        if (row.status == "waiting_sms") {
          return (
            <CButton
              color={!isMobile ? "secondary" : "link"}
              variant="outline"
              size="sm"
              onClick={() => cancelOrder(row.id)}
              disabled={loading && loadingId == row.id}
              // onClick={() => createOrder(row.productId)}
            >
              {loading && loadingId == row.id ? (
                <CSpinner size="sm" color="secondary" />
              ) : (
                <CIcon icon={cilXCircle} />
              )}
            </CButton>
          );
        }
      },
      sortable: false,
      // width: "60px",
    });
  needUser &&
    columns.unshift({
      id: "userId",
      name: t(`table.user`),
      center: true,
      selector: (row) => (
        <CTooltip content={<>{`${row.user.email || "—"}`}</>}>
          {row.user.id ? (
            <a href={`/admin/users/${row.user.id}`} target="_blank">{row.user.id || "—"}</a>
          ) : (
            <div>{row.user.id || "—"}</div>
          )}
        </CTooltip>
      ),
      sortable: false,
      notMobile: true,
    });
  columns.unshift({
    id: "id",
    name: t("common.id"),
    center: true,
    selector: (row) => row.id || "—",
    sortable: false,
    compact: true,
    notMobile: true,
  });
  const renderMobileItem = (row) => {
    return (
      <div className="mobile-table__item">
        {isMobile && (
          <>
            <div className="mobile-info mobile-info_transparent">
              <CTooltip
                content={
                  <div className="tooltip-item">
                    {columns.map((el) => {
                      return (
                        (el.id == "id" || el.id == "createDate") && (
                          <div className="tooltip-row">
                            <div className="tooltip-key">{el.name}</div>
                            <div className="tooltip-key">
                              {el.selector(row)}
                            </div>
                          </div>
                        )
                      );
                    })}
                  </div>
                }
              >
                <CIcon icon={cilOptions} />
              </CTooltip>
              {needCancel && row.status == "waiting_sms" && (
                <CButton
                  color={!isMobile ? "secondary" : "link"}
                  variant="outline"
                  size="sm"
                  onClick={() => cancelOrder(row.id)}
                  disabled={loading && loadingId == row.id}
                  // onClick={() => createOrder(row.productId)}
                >
                  {loading && loadingId == row.id ? (
                    <CSpinner size="sm" color="secondary" />
                  ) : (
                    <CIcon icon={cilX} />
                  )}
                </CButton>
              )}
            </div>
          </>
        )}
        {columns.map((el) => {
          if (el.id == "order_action") {
            return;
          }
          if (el.id == "phone") {
            return (
              <div className="mobile-table__row">
                <div className="mobile-table__key">
                  <div>
                    {row.serviceUid &&
                      renderIcon(row.serviceUid, row.countryAlpha2)}
                  </div>
                </div>
                <div className="mobile-table__value">{el.selector(row)}</div>
              </div>
            );
          }
          return !el.notMobile ? (
            <div className="mobile-table__row">
              {el.name && <div className="mobile-table_key">{el.name}</div>}
              {el.selector(row) && (
                <div className="mobile-table__value">{el.selector(row)}</div>
              )}
            </div>
          ) : (
            <></>
          );
        })}
      </div>
    );
  };
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <CSpinner color="primary" />
          </div>
        )}
        {(filters?.length > 0 || sorting?.length > 0) && (
          <>
            <div className="filters-mobile">
              {title && <h6>{title}</h6>}

              <span onClick={() => setShowFilters(true)}>
                <CIcon icon={cilListFilter} />
                {t("filters")}
              </span>
            </div>{" "}
            {showFilters && (
              <div
                className="filters-plug"
                onClick={() => setShowFilters(false)}
              />
            )}
            <div className={`filters-wrapper ${showFilters ? "active" : ""}`}>
              {showFilters && (
                <div
                  className="filters-close"
                  onClick={() => setShowFilters(false)}
                >
                  <CIcon icon={cilX} />
                </div>
              )}
              {filters?.length > 0 && (
                <>
                  <Filters
                    title={title || ""}
                    items={filters}
                    setFilters={(val) => {
                      setFilters(val);
                      setCount(count + 1);
                      if (page == 1 && count > 0) {
                        getOrders(page, perPage, val, false);
                      }
                    }}
                  />
                  <CButton
                    onClick={() => getOrders(page, perPage, filters, true)}
                    type="button"
                    variant="outline"
                    color="primary"
                  >
                    {t("ip.export_csv")}
                  </CButton>
                </>
              )}
              {sorting?.length > 0 && orders?.length > 0 && (
                <>
                  <Sorting
                    items={sorting}
                    setItems={setSorting}
                    active={activeSorting}
                  />
                </>
              )}
            </div>
          </>
        )}
        <div className="table-wrapper-block">
          {!isMobile ? (
            <DataTable
              columns={columns}
              data={orders}
              sortIcon={<CIcon icon={cilArrowTop} />}
              noDataComponent={t("table.notFound")}
              // progressPending={isLoading}
              progressComponent={<CSpinner color="primary" />}
            />
          ) : (
            <div className="mobile-table">
              {orders?.length > 0 ? (
                orders.map((row) => {
                  if (needCancel && row.status == "waiting_sms") {
                  }
                  return renderMobileItem(row);
                })
              ) : (
                <div className="mobile-table__empty">{t("table.notFound")}</div>
              )}
            </div>
          )}
        </div>
        {orders?.length > 0 && (
          <>
            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              setPerPage={(val) => {
                setPerPage(val);
                if (page == 1) {
                  getOrders(page, val, filters, false);
                }
              }}
            />
          </>
        )}
      </div>
    </>
  );
}
export default OrderTable;
