import React, { useState, useEffect } from "react";
import "./index.scss";
import { useTranslation } from "react-i18next";
import DataTable from "react-data-table-component";
import CIcon from "@coreui/icons-react";
import {
  cilArrowTop,
  cilClock,
  cilX,
  cibVk,
  cilCheck,
  cilOptions,
  cilListFilter,
} from "@coreui/icons";
import { CSpinner, CTooltip } from "@coreui/react";

import TablePagination from "components/TablePagination";
import Filters from "components/Filters";
import { useWindowSize } from "utils/utils";
import Sorting from "components/Sorting";

function TransactionsTable(props) {
  const {
    currency,
    payments,
    page,
    setPage,
    total,
    perPage,
    setPerPage,
    filters,
    setFilters,
    isLoading,
    getPayments,
    sorting,
    activeSorting,
    setSorting,
  } = props;

  const windowSize = useWindowSize()[0];
  const [showFilters, setShowFilters] = useState(false);
  const isMobile = windowSize < 767;

  useEffect(() => {
    setPage(1);
  }, [perPage]);
  useEffect(() => {
    getPayments();
  }, [page, filters, activeSorting]);

  const renderStatus = (status) => {
    return t(`table.${status}`);
  };
  const renderIcon = (iconName, country) => {
    const icons = {
      vk: cibVk,
    };
    if (!icons[iconName]) {
      return `${iconName} - ${country}`;
    } else {
      return (
        <>
          <CIcon icon={icons[iconName]} /> - {country}
        </>
      );
    }
  };
  const { t } = useTranslation();
  const columns = [
    {
      id: "id",
      name: t("common.id"),
      center: true,
      selector: (row) => row.id || "—",
      notMobile: true,
    },
    {
      id: "createDate",
      name: t("table.date"),
      center: true,
      selector: (row) =>
        (new Date(row.createdAt).toLocaleString() && !isMobile
          ? new Date(row.createdAt).toLocaleString()
          : new Date(row.createdAt).toLocaleString().split(",")?.[0]) || "—",
      notMobile: true,
    },
    {
      id: "serviceUid",
      name: t("table.action"),
      center: true,
      selector: (row) => t(`table.${row.action}`) || "—",
    },
    {
      id: "provider",
      name: t("table.provider"),
      center: true,
      selector: (row) => t(`${row.provider}`) || "—",
      notMobile: true,
    },
    {
      id: "description",
      name: t("table.description"),
      center: true,
      selector: (row) => row.description || "—",
    },
    {
      id: "amount",
      name: t("table.amount"),
      center: true,
      selector: (row) => (
        <>
          {new Intl.NumberFormat("ru-RU", {
            style: "currency",
            currency: currency,
          }).format((Math.floor(row.amount * 100) / 100).toFixed(2))}
        </>
      ),
    },
    {
      id: "status",
      name: t("table.state"),
      center: true,
      selector: (row) => (row.state ? t(`table.${row.state}`) : "-"),
    },
  ];
  return (
    <>
      <div className="orders-table">
        {isLoading && (
          <div className="with-preloader">
            <CSpinner color="primary" />
          </div>
        )}
        <div className="orders-table">
          <h6 className="hidden_sm hidden_md">
            {t("heading.transactions")} - {total}
          </h6>
          {filters?.length > 0 ||
            (sorting?.length > 0 && (
              <>
                <div className="filters-mobile">
                  {<h6>{t("heading.transactions")}</h6>}

                  <span onClick={() => setShowFilters(true)}>
                    <CIcon icon={cilListFilter} />
                    {t("filters")}
                  </span>
                </div>{" "}
                {showFilters && (
                  <div
                    className="filters-plug"
                    onClick={() => setShowFilters(false)}
                  />
                )}
                <div
                  className={`filters-wrapper ${showFilters ? "active" : ""}`}
                >
                  {showFilters && (
                    <div
                      className="filters-close"
                      onClick={() => setShowFilters(false)}
                    >
                      <CIcon icon={cilX} />
                    </div>
                  )}
                  {filters?.length > 0 && (
                    <Filters
                      items={filters}
                      setFilters={setFilters}
                      title={`${t("heading.payments")} — ${total}`}
                    />
                  )}
                  {sorting?.length > 0 && (
                    <>
                      <Sorting
                        items={sorting}
                        setItems={setSorting}
                        active={activeSorting}
                      />
                    </>
                  )}
                </div>
              </>
            ))}
          {!isMobile ? (
            <DataTable
              columns={columns}
              data={payments}
              sortIcon={<CIcon icon={cilArrowTop} />}
              // progressPending={isLoading}
              progressComponent={<CSpinner color="primary" />}
              noDataComponent={t("table.notFound")}
            />
          ) : (
            <div className="mobile-table">
              {payments?.length > 0 ? (
                payments.map((row) => {
                  return (
                    <div className="mobile-table__item">
                      {isMobile && (
                        <>
                          <CTooltip
                            content={
                              <div className="tooltip-item">
                                {columns.map((el) => {
                                  return (
                                    (el.id == "id" ||
                                      el.id == "createDate" ||
                                      el.id == "provider") && (
                                      <div className="tooltip-row">
                                        <div className="tooltip-key">
                                          {el.name}
                                        </div>
                                        <div className="tooltip-key">
                                          {el.selector(row)}
                                        </div>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                            }
                          >
                            <div className="mobile-info mobile-info_transparent">
                              <CIcon icon={cilOptions} />
                            </div>
                          </CTooltip>
                        </>
                      )}
                      {columns.map((el) => {
                        if (el.id == "phone") {
                          return (
                            <div className="mobile-table__row">
                              <div className="mobile-table__key">
                                <div>
                                  {row.serviceUid &&
                                    renderIcon(
                                      row.serviceUid,
                                      row.countryAlpha2
                                    )}
                                </div>
                              </div>
                              <div className="mobile-table__value">
                                {el.selector(row)}
                              </div>
                            </div>
                          );
                        }
                        return !el.notMobile ? (
                          <div className="mobile-table__row">
                            {el.name && (
                              <div className="mobile-table_key">{el.name}</div>
                            )}
                            {el.selector(row) && (
                              <div className="mobile-table__value">
                                {el.selector(row)}
                              </div>
                            )}
                          </div>
                        ) : (
                          <></>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <div className="mobile-table__empty">{t("table.notFound")}</div>
              )}
            </div>
          )}
          {payments?.length > 0 && (
            <TablePagination
              page={page}
              setPage={setPage}
              total={total}
              perPage={perPage}
              setPerPage={setPerPage}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default TransactionsTable;
